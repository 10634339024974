<template>
    <footer>
        <div class="container">
            <div class="wrapper">
                <div class="divider footer-one">
                    <div class="ftr_logo">
                        <router-link to="/">
                            <img src="@/assets/images/logo.svg" alt="">
                        </router-link>
                    </div>
                    <div class="ttx">
                        <!-- <h5>About <span>cSIGMA</span></h5> -->
                        <p>{{ themeSettingsData.footer_about_description }}</p>
                    </div>
                </div>
                <div class="divider">
                    <ul class="ftr-link">
                        <li v-for="(row) in footerMenuData" :key="row">
                            <a target="_blank" v-if="row.url=='https://csigma.finance/dash/wp-content/uploads/2024/06/CSigma-Finance-Token-Blueprint_Distribution.pdf'" :href="row.url">{{ row.title }}</a>
                            <router-link v-else :to="row.url">{{ row.title }}</router-link>

                        </li>
                    </ul>
                    <ul class="ftr-link">
                        <li v-for="(row) in footerMenu2Data" :key="row">
                            <!-- <router-link :to="row.url" @click="moveUp">{{ row.title }}</router-link> -->
                            <a :href="row.url" target="_blank">{{ row.title }}</a>

                        </li>
                    </ul>
                    <ul class="ftr-link">
                        <li v-for="(row) in footerMenu3Data" :key="row">
                            <!-- <router-link :to="row.url" @click="moveUp">{{ row.title }}</router-link> -->
                            <router-link :to="row.url">{{ row.title }}</router-link>

                        </li>
                    </ul>

                    <div class="link-spc">
                        <p>Join our community</p>
                        <ul>
                            <!-- <li>
                                <a :href="themeSettingsData.facebook_link">
                                    <img src="@/assets/images/icon/facebook.svg" alt="">
                                </a>
                            </li> -->
                            <li>
                                <a :href="themeSettingsData.twitter_link" target="_blank" v-if="themeSettingsData.twitter_link">
                                    <img src="@/assets/images/twitter.png" alt="">
                                </a>
                                <a href="#" v-else>
                                    <img src="@/assets/images/twitter.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a :href="themeSettingsData.linkedin_link" target="_blank" v-if="themeSettingsData.linkedin_link">
                                    <img src="@/assets/images/linkdin.png" alt="">
                                </a>
                                <a href="#" v-else>
                                    <img src="@/assets/images/linkdin.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a :href="themeSettingsData.discord_link" target="_blank">
                                    <img src="@/assets/images/reading.png" alt="">
                                </a>
                            </li>
                            <li>
                                <a :href="themeSettingsData.github_link" target="_blank">
                                    <img src="@/assets/images/githubicon.png" alt="">
                                </a>
                            </li>
                            <!-- <li>
                                <a :href="themeSettingsData.instagram_link">
                                    <img src="@/assets/images/icon/instagram.svg" alt="">
                                </a>
                            </li> -->
                        </ul>
                        <div class="footer-audit">
                            <p>Audited By</p>
                            <!-- <img :src="themeSettingsData.audited_by_image" alt=""> -->
                            <a :href="themeSettingsData.audited_by" target="_blank">
                                <img :src="themeSettingsData.audited_by_image" alt=""></a><br />
                            <a :href="themeSettingsData.audited_by1" target="_blank">
                                <img :src="themeSettingsData.audited_by_image1" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="copy-rti">
                <!-- <p>{{ themeSettingsData.footer_copyright_section }}</p> -->
                <p>© {{currentYear()}} cSIGMA - All rights Reserved</p>
            </div>
        </div>
    </footer>
</template>


<script>
import { mapState } from 'vuex';

export default {
    name: 'App',
    computed: {
        ...mapState({
            footerMenuData: state => state.webapiMod.footerMenuData,
            footerMenu2Data: state => state.webapiMod.footerMenu2Data,
            footerMenu3Data: state => state.webapiMod.footerMenu3Data,
            themeSettingsData: state => state.webapiMod.themeSettingsData,
        }),
    },
    watch: {
        themeSettingsData() {
        },
    },
    mounted() {
        // this.GetThemeSettingsData();
        // const getFooterMenuDataBySlug = {
        //     menu_slug: 'footer_menu',
        // };
        // this.GetFooterMenuData(getFooterMenuDataBySlug);
    },
    methods: {
        currentYear() {
            return new Date().getFullYear();
        }
        // ...mapActions({
        //     GetFooterMenuData: "webapiMod/GetFooterMenuData",
        //     GetThemeSettingsData: 'webapiMod/GetThemeSettingsData',
        // }),
    },
}
</script>
