import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './routes'
import axios from 'axios'
import VueLazyLoad from 'vue3-lazyload'
import './assets/style.css'
import './assets/media.css'
// import './assets/fonts.css'
const axiosInstance = axios.create({
  withCredentials: true,
})

import { createMetaManager,plugin as metaPlugin } from 'vue-meta'
const metaManager = createMetaManager();

const app = createApp(App);
app.config.globalProperties.$axios = { ...axiosInstance };
app.config.globalProperties.$apiUrl = process.env.VUE_APP_APIURL;
app.config.globalProperties.$wpApiUrl = process.env.VUE_APP_API_BASE_URL;
app.use(metaManager).use(metaPlugin);
app.use(VueLazyLoad);
app.use(store)
app.use(router);
app.mount('#app');
// createApp(App).use(router).mount('#app')
