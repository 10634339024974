import { createWebHistory, createRouter} from "vue-router";
import Index from './components/pages/Index.vue';
import AboutUs from './components/pages/AboutUs.vue';
import ThePlatform from "./components/pages/ThePlatform.vue";
import Lender from "./components/pages/Lender.vue";
import Borrow from "./components/pages/Borrow.vue";
import ContactUs from './components/pages/ContactUs.vue';
import TermNConditions from "./components/pages/TermNConditions.vue";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import PageNotFound from "./components/pages/PageNotFound";
import ProtocolPage from "./components/pages/ProtocolPage";
import EdgePage from "./components/pages/EdgePage";


const routes = [
    {
        name: 'Index',
        component: Index,
        path: '/',
        meta: {
            title: 'Index',
            requiresAuth: false
        },
    },
    {
        name: 'ContactUs',
        component: ContactUs,
        path: '/contactUs',
        meta: {
            title: 'ContactUs',
            requiresAuth: false
        },
    },
    {
        name: 'AboutUs',
        component: AboutUs,
        path: '/about-us',
        meta: {
            title: 'AboutUs',
            requiresAuth: false
        },
    },
    {
        name: 'Borrow',
        component: Borrow,
        path: '/borrow',
        meta: {
            title: 'Borrow',
            requiresAuth: false
        },
    },
    {
        name: 'lender',
        component: Lender,
        path: '/lender',
        meta: {
            title: 'Lender',
            requiresAuth: false
        },
    },
    {
        name: 'the-platform',
        component: ThePlatform,
        path: '/the-platform',
        meta: {
            title: 'ThePlatform',
            requiresAuth: false
        },
    },
    {
        name: 'term-conditions',
        component: TermNConditions,
        path: '/term-conditions',
        meta: {
            title: 'TermNConditions',
            requiresAuth: false
        },
    },
    {
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        path: '/privacy-policy',
        meta: {
            title: 'PrivacyPolicy',
            requiresAuth: false
        },
    },
    {
        name: 'PageNotFound',
        component: PageNotFound,
        path: '/:pathMatch(.*)*',
        meta: {
            title: 'PageNotFound',
            requiresAuth: false
        },
    },
    {
        name: 'ProtocolPage',
        component: ProtocolPage,
        path: '/protocol',
        meta: {
            title: 'Protocol',
            requiresAuth: false
        },
    },
    {
        name: 'EdgePage',
        component: EdgePage,
        path: '/edge',
        meta: {
            title: 'Edge',
            requiresAuth: false
        },
    },
]

const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
	routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
});

export default router;


