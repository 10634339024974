<template>
    <metainfo>
      <!-- <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template> -->
      <template v-slot:title="{ content }">{{ content ? content : `cSigma` }}</template>
    </metainfo>
    <div>
      <Header/>
      <router-view/>
      <Footer/>
    </div>
</template>

<script>
import Header from './components/includes/Header.vue'
import Footer from './components/includes/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
