<template>
  <div class="mnht">
      <div v-if="isload">
            
          <div class="banner about__banner fix-top">
              <div class="container">
                  <div class="wrapper">
                      <div class="divider">
                          <div class="text-area">
                              <h2 v-html="banner_sec.banner_section_heading"></h2>
                              <h4 v-html="banner_sec.banner_section_sub_heading"></h4>
                              <router-link :to="banner_sec.banner_section_button_url" class="btn-ik yelw-cta">
                                  {{ banner_sec.banner_section_button_name }} <img src="@/assets/images/icon/arrow_right_blck.svg" alt="">
                              </router-link>
                          </div>
                      </div>
                    
                      <div class="divider">
                          <div class="brn-ser">
                              <img :src="banner_sec.banner_section_right_image" alt="">
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
          <div class="our-mission new-mission-bg">
              <div class="container">
                  <div class="section-head">
                      <h3>{{ sec2_our_mission.our_mission_heading }}</h3>
                      <p v-html="sec2_our_mission.our_mission_sub_heading"></p>
                  </div>
                  <div class="wrapper">
                      <div class="divider">
                          <div class="item">
                              <p v-html="sec2_our_mission.our_mission_left_content"></p>
                          </div>
                      </div>
                      <div class="divider">
                          <div class="item">
                              <p v-html="sec2_our_mission.our_mission_right_content"></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
    
          <div class="our-team new-team">
              <div class="container">
                  <div class="section-head">
                      <h3>{{ sec3_our_team.our_team_heading }}</h3>
                      <p>{{ sec3_our_team.our_team_sub_heading }}</p>
                  </div>
                  <div class="wrapper">
                      <div class="divider" v-for="(row) in sec3_our_team.our_team" :key="row">
                          <div class="item">
                              <div class="thma">
                                  <img :src="row.image" alt="">
                              </div>
                              <div class="ttx">
                                  <div class="ix-align">
                                      <h4>{{ row.name }}</h4>
                                      <a v-if="row.social_media_url" :href="row.social_media_url" target="_blank">
                                          <img src="@/assets/images/icon/linkedin-blue.svg" alt="">
                                      </a>
                                  </div>
                                  <p>{{ row.designation }}</p>
                                  <!-- <p>{{ row.short_desc }}<span v-if="row.readMore == 1">...</span></p> -->
                                  <!-- <a href="#" class="transp-cta">Learn More <img src="@/assets/images/icon/arrow_right_blck.svg" alt=""></a> -->
                                  <!-- <div class="read-btn" v-if="row.readMore == 1">
                                      <button @click="ourTeamModal(row.image, row.name, row.designation, row.description, row.social_media_url)">Read More</button>
                                  </div> -->
                              </div>
                          </div>
                      </div>
                  </div>
                
                
              </div>
          </div> 

          <div class="our-team new-team dev-team">
              <div class="container">
                    <div class="section-head">
                      <h3>{{ sec4_our_dev_team_arr.our_dev_team_heading }}</h3>                     
                    </div>
                  <div class="wrapper">
                      <div class="divider" v-for="(row) in sec4_our_dev_team_arr.our_dev_team" :key="row">
                        <div class="item">                             
                            <div class="ttx">
                                <div class="ix-align">
                                    <h4>{{ row.name }}</h4>
                                    <a v-if="row.social_media_url" :href="row.social_media_url" target="_blank">
                                        <img src="@/assets/images/icon/linkedin-blue.svg" alt="">
                                    </a>
                                </div>
                                <p>{{ row.designation }}</p>                             
                            </div>
                        </div>
                      </div>
                  </div>
                
                
              </div>
            </div> 
    
    
          <!-- <div class="markets-platform about-platform">
              <div class="container">
                  <router-link :to="sec3_our_team.our_team_button_url" class="btn-ik yelw-cta">{{ sec3_our_team.our_team_button_name }} <img src="@/assets/images/icon/arrow_right_blck.svg" alt=""></router-link>
              </div>
          </div> -->
      </div>
  </div>

  <transition name="modal">
      <Modal v-if="showModalFirst" @close="showModalFirst = false">
          <template v-slot:header>

          <div class="modal-prifile-top">
              <div class="modal-prifile-image">
                  <img :src="this.image" alt="">
              </div>

              <div>
                  <div class="ix-align">
                      <h4>{{ this.name }}</h4>
                      <a v-if="this.social_media_url" :href="this.social_media_url" target="_blank">
                      <img src="@/assets/images/icon/linkedin-blue.svg" alt="">
                      </a>
                  </div>
                  <p>{{ this.designation }}</p>
              </div>
          </div>
                        
          </template>
          <template v-slot:body>
              <p v-html="this.description"></p>
          </template>
      </Modal>
  </transition>

</template>



<script>
  import { mapActions, mapState } from "vuex";
  import Modal from '../includes/Modal.vue'
  import { useMeta } from 'vue-meta'
  import { onMounted } from 'vue';

  export default {
    name: 'App',
    components: {
      Modal
    },
    data() {
        return {
          isload: false,
          // isloading:false,
          banner_sec: '',
          sec2_our_mission: '',
          sec3_our_team: '',
          section4_cmp: '',
          sec4_our_dev_team_arr:'',

          showModalFirst: false,
          showModal: false,

          name: '',
          image: '',
          designation: '',
          description: '',
          social_media_url: '',
        }
    },
      mounted() {
      // this.isloading=true;
      // this.GetAboutUs();
    },
    computed: {
      ...mapState({
        aboutus: state => state.webapiMod.aboutus
      }),
    },
    watch: {
      aboutus() {
        this.banner_sec = this.aboutus.sec1_banner_sec;
        this.sec2_our_mission = this.aboutus.sec2_our_mission;
        this.sec3_our_team = this.aboutus.sec3_our_team;
        this.sec4_our_dev_team_arr= this.aboutus.sec4_our_dev_team_arr;
        this.isload = true;
        // this.isloading=false;
      }
    },
    methods: {
      ...mapActions({
        GetAboutUs: "webapiMod/GetAboutUs",
      }),

      ourTeamModal(image, name, designation, description, social_media_url) {
        console.log('TTT', this.designation);
        this.image = image;
        this.name = name;
        this.designation = designation;
        this.description = description;
        this.social_media_url = social_media_url;
        this.showModalFirst = true;
      },

    },
    created() {
      // this.GetAboutUs();
      if (this.aboutus.length == 0) {
        this.GetAboutUs();
      } else {
        this.banner_sec = this.aboutus.sec1_banner_sec;
        this.sec2_our_mission = this.aboutus.sec2_our_mission;
        this.sec3_our_team = this.aboutus.sec3_our_team;
        this.sec4_our_dev_team_arr= this.aboutus.sec4_our_dev_team_arr;
        this.isload = true;
      }
    },
    
    setup() {
        const { meta } = useMeta({});
        const wpApiUrl = process.env.VUE_APP_API_BASE_URL;
        onMounted(async () => {
            let response = await fetch(wpApiUrl + 'wp/v2/pages?slug=about-us');
            let data = await response.json();
            data[0].yoast_head_json.schema = {};
            let dataset = data[0].yoast_head_json;
            dataset.canonical = window.location.href;
            dataset.og_url = window.location.href;
            Object.entries(dataset).forEach(([key, value]) => {
                if (value != '')
                    meta[key] = value;
            });
            // console.log(dataset)
            // console.log(meta)
        });
        return { meta };
    },
  }
</script>
